
import { defineComponent } from 'vue';
import PersonalDetailsForm from '@/components/forms/profile/blocks/PersonalDetails.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import AddressLookup from '@/components/AddressLookup.vue';

export default defineComponent({
  name: 'personal-details',
  emits: [
    'peronsalStateChange',
    'peronsalStateBlur',
    'peronsalCountryChange',
    'peronsalCountryBlur',
    'onSearchAddress'
  ],
  props: {
    values: {
      type: Object,
      default: () => undefined,
    },
  },
  data: () => ({
    states: [],
    isManual: false
  }),
  components: {
    PersonalDetailsForm,
    AddressForm,
    AddressLookup
  },
  methods: {
    handleInput(e) {
      console.log('handleInput', e);
    },
    handleCountryChange(value) {
      this.$emit('peronsalCountryChange', value);
    },
    handleCountryBlur() {
      this.$emit('peronsalCountryBlur');
    },
    handleStateChange(value) {
      this.$emit('peronsalStateChange', value);
    },
    handleStateBlur() {
      this.$emit('peronsalStateBlur');
    },
    setPlace(place){
      this.$emit('onSearchAddress', place)
    }
  },
});
