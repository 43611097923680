import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-6 col-md-6" }
const _hoisted_4 = { class: "col-lg-6 col-md-6" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormField, {
          required: true,
          name: "first_name",
          label: "First Name",
          placeholder: "First Name",
          type: "text"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FormField, {
          required: true,
          name: "last_name",
          label: "Surname",
          placeholder: "Surname",
          type: "text"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_FormField, {
        required: true,
        name: "date_of_birth",
        containerClass: "col-lg-4 col-md-4",
        label: "Date of Birth",
        placeholder: "Pick a date",
        dateFormat: "DD/MM/YYYY",
        disabledDates: _ctx.disableFutureDates,
        type: "datepicker"
      }, null, 8, ["disabledDates"]),
      _createVNode(_component_FormField, {
        name: "mobile_number",
        containerClass: "col-lg-4 col-md-4",
        label: "Mobile Number",
        placeholder: "Mobile Number",
        type: "text",
        mask: _ctx.maskingMobile
      }, null, 8, ["mask"]),
      _createVNode(_component_FormField, {
        value: _ctx.values.email,
        required: true,
        disabled: true,
        name: "email",
        containerClass: "col-lg-4 col-md-4",
        label: "Email",
        placeholder: "Email",
        type: "email",
        onInput: _ctx.handleEmailInput
      }, null, 8, ["value", "onInput"])
    ])
  ]))
}