
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'stepper-nav',
  props: {
    stepClass: {
      type: String,
      default: '',
    },
    stepNumber: {
      type: Number,
      default: 1,
    },
    stepTitle: {
      type: String,
      default: '',
    },
    stepDescription: {
      type: String,
      default: '',
    },
  },
});
