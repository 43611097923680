
import { defineComponent } from 'vue';
import FormField from '@/components/forms/FormField.vue';

export default defineComponent({
  name: 'create-new-password',
  components: {
    FormField,
  },
});
