
import { defineComponent } from 'vue';
import CompleteProfileForm from '@/views/v2/views/pages/CompleteProfile/CompleteProfileForm.vue';

export default defineComponent({
  name: 'complete-profile-page',
  components: {
    CompleteProfileForm,
  },
});
