
import { defineComponent } from 'vue';
import StepperNav from '@/views/v2/views/pages/CompleteProfile/Stepper.vue';
import { Getters, Actions } from '@/store/enums/AuthEnums';
import { mapActions, mapGetters } from 'vuex';
import { Form } from 'vee-validate';

import PersonalDetails from '@/views/v2/views/pages/CompleteProfile/PersonalDetails.vue';
import CreatePassword from '@/views/v2/views/pages/CompleteProfile/ChangePassword.vue';
import UploadProfilePicture from '@/views/v2/views/pages/CompleteProfile/UploadProfilePicture.vue';

import { ProfileSchema, NewPasswordSchema } from '@/schemas/profile.schema';
import { Auth } from '@/models/AuthModel';
import { StepperComponent } from '@/assets/ts/components';
import { Getters as UserGetters } from '@/store/enums/UserEnums';

import {
  Actions as TActions,
  Getters as TGetters,
} from '@/store/enums/TenantEnums';
import moment from 'moment';
import toasts from '@/utils/toasts';
import router from '@/router';

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  date_of_birth: '',
  country: 'AU',
  state: '',
  city: '',
  address_line: '',
  post_code: ''
} as unknown as Auth;

export default defineComponent({
  name: 'complete-profile-form',
  components: {
    Nav: StepperNav,
    Form,
    CreatePassword,
    PersonalDetails,
    //UploadProfilePicture,
  },
  data: () => ({
    currentStep: 0,
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    stepper: {} as StepperComponent,
    profileImage: '',
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.completeProfileForm as typeof Form;

    // setup the stepper
    this.stepper = StepperComponent.createInsance(
      this.$refs.completeProfileStepperForm as HTMLElement
    ) as StepperComponent;
  },
  computed: {
    ...mapGetters({
      authUser: Getters.GET_AUTH_USER,
      actionErrors: Getters.GET_AUTH_USER_ACTION_ERROR,
    }),
    hasPassword() {
      return this.authUser?.create_new_password;
    },

    formStepSchema() {
      return this.formSchema[this.currentStep];
    },

    formSchema() {
      if (this.hasPassword) {
        return [NewPasswordSchema, ProfileSchema];
      }
      return [ProfileSchema];
    },

    totalSteps(): number {
      if (!this.stepper) {
        return 0;
      }

      if (!this.hasPassword) {
        return this.stepper.totatStepsNumber - 1;
      }

      return this.stepper.totatStepsNumber;
    },

    initValues() {
      return {
        ...this.formData,
        ...{
          email: this.authUser.email,
          country: this.authUser.country ? this.authUser.country : 'AU',
        },
      };
    },
  },
  methods: {
    ...mapActions({
      submitCompleteProfileForm: Actions.UPDATE_USER_PROFILE,
      uploadProfilePicture: Actions.UPLOAD_PROFILE_PICTURE,
    }),
    handlePersonalStateChange(value) {
      this.setFieldValue('state', value);
    },
    handlePersonalStateBlur() {
      this.validateFormField('state');
    },
    handlePersonalCountryChange(value) {
      this.setFieldValue('country', value);
    },
    handlePersonalCountryBlur() {
      this.validateFormField('country');
    },
    handleUploadPicture(value) {
      this.profileImage = value;
    },
    nextStep(values) {
      if (this.loading) return;
      this.loading = true;

      this.formData = {
        ...this.formData,
        ...values,
      };

      this.currentStep++;
      if (!this.stepper) {
        this.loading = false;
        return;
      }

      this.form.resetForm();
      this.loading = false;
      this.stepper.goNext();
    },
    previousStep() {
      if (!this.stepper) {
        return;
      }

      this.currentStep--;
      this.stepper.goPrev();
    },
    resetForm(ev?: Event) {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form stepper
      this.currentStep = 0;
      this.stepper.goFirst();

      // Reset the form values
      this.form.setValues(initialValues);
      this.formData = initialValues;

      // Hide the modal
      if (!ev) {
        this.loading = false;
      }
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    async handleSubmitCompleteProfileForm(values) {
      if (this.loading) return;
      this.loading = true;

      this.formData = {
        ...this.formData,
        ...values,
        ...{
          date_of_birth: moment(values.date_of_birth).format('YYYY-MM-DD'),
        },
      };

      console.log('values', values);
      console.log('formData', this.formData);

      const {valid} = await (
        this.$refs.completeProfileForm as any
      ).validate();

      if (!valid) {
        this.loading = false;
        return false;
      }

      /**
       * Delete pre-filled ID from review
       *
       * Note: Do not remove
       */
      delete this.formData.id;
      // End: Delete pre-filled ID from review

      // if (this.profileImage) {
      //   console.log('this.profileImage', this.profileImage);
      //   await this.uploadProfilePicture(this.profileImage).catch(() => {
      //     const { errors, message } = this.errors;
      //     toasts.error(errors, message);
      //   });
      // }

      this.submitCompleteProfileForm(this.formData)
        .then(() => {
          this.loading = false;
          this.resetForm();
          toasts.success('Account profile completed!', () => {
            router.push({ name: 'dashboard' });
          });
        })
        .catch(() => {
          this.loading = false;
          const { errors, messages } = this.actionErrors;
          toasts.error(errors, messages);
        });
    },
    setPlace(place){
      this.setFieldValue('state', place.state);
      this.setFieldValue('country', place.country);
      this.setFieldValue('post_code', place.post_code);
      this.setFieldValue('city', place.city);
      this.setFieldValue('address_line', place.address);
    }
  },
  watch: {
    authUser(value) {
      if (!value) return;

      this.formData = {
        ...this.formData,
        ...value,
        ...{
          email: value.email ?? value.email,
          country: value.country ?? 'AU',
        },
      };
    },
  },
});
