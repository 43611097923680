import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormField, {
      required: true,
      containerClass: "flex-fill w-lg-100 w-50 w-sm-100",
      label: "New Password",
      name: "password",
      placeholder: "New Password",
      type: "password"
    }),
    _createVNode(_component_FormField, {
      required: true,
      containerClass: "flex-fill w-lg-100 w-50 w-sm-100",
      label: "Confirm New Password",
      name: "password_confirmation",
      placeholder: "Confirm New Password",
      type: "password"
    })
  ]))
}